















































import {Component, Prop, Vue} from 'vue-property-decorator'
import {DocumentPresetResource} from '@/models/documents/DocumentPresetResource'
import {DocumentFilterResource} from '@/models/documents/DocumentFilterResource'

@Component
export default class FilterTooltip extends Vue {

  @Prop()
  private preset?: DocumentPresetResource

  @Prop()
  private filters!: Array<DocumentFilterResource & { value: string | Array<string | null> }>

  private show: boolean = false

  private getFilterOptionLabel(
      filter: DocumentFilterResource & { value: string | Array<string | null> },
      item: string
  ): string {
    const options = this.flattenOptions(filter?.options ?? [])
    const option = options.find((f) => f.value?.toString().toLowerCase() === item.toString().toLowerCase())

    return option?.label ?? ''
  }

  private flattenOptions(optionItems: SelectItem[]): SelectItem[] {
    const options: SelectItem[] = []
    for (const option of optionItems) {
      options.push(...this.getOptionsFromArray(option))
    }
    return options
  }

  private getOptionsFromArray(option: SelectItem): SelectItem[] {
    const options: SelectItem[] = []
    options.push(option)
    if (!option.options) return options
    for (const nestedOption of option.options) {
      options.push(...this.getOptionsFromArray(nestedOption))
    }
    return options
  }

}
